const androidVersion = parseFloat(getAndroidVersion());

function getAndroidVersion(ua) {
  ua = (ua || navigator.userAgent).toLowerCase(); 
  var match = ua.match(/android\s([0-9\.]*)/i); // eslint-disable-line
  return match ? match[1] : undefined;
}

if (androidVersion <= 7) {
  const warning = $('.warning');
  const warningHeight = warning.height() + 'px';

  $('body').css('padding-bottom', warningHeight);
  warning.show();
}
