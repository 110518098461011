const region_selector = '.js-region-selector select';
let selected_region = $(region_selector + ' :selected');
let district_selector = '.js-district-selector';

$('#locationModal').on('click', dynamicallyDisplayDistrictDropdown(region_selector,district_selector));

function dynamicallyDisplayDistrictDropdown(region_selector,district_selector) {
  $(region_selector).change(function() {
    selected_region = $(region_selector + ' :selected');
    $(district_selector).hide();
    $(district_selector + ' select').attr('disabled', true);

    displaySelectedDistrict(district_selector, selected_region);
  });
}

function displaySelectedDistrict(district_selector, selected_region) {
  $(district_selector+'[data-region-name="' + selected_region.val() + '"]').removeClass('hide d-none');
  $(district_selector+'[data-region-name="' + selected_region.val() + '"]').show();
  $(district_selector+'[data-region-name="' + selected_region.val() + '"] select').removeAttr('disabled');
}

$('.js-location-selector').on('click', function() {
  let selected_district = $(district_selector+'[data-region-name="' + selected_region.val() + '"] select :selected');
  let all_districts_selected = selected_district.text()===`All ${selected_region.text()} districts`;
  if (selected_district.length == 0 || all_districts_selected ) {
    $('.js-location-selected').text($(selected_region).text());
  }
  else{
    $('.js-location-selected').text($(selected_district).text());
  }
});
